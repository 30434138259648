import React from "react";
import ClassContainer from "../../containers/Class";
import Layout from "../../Layout";

const Class = () => {
  return (
    <Layout>
      <ClassContainer />
    </Layout>
  );
};

export default Class;

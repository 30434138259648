import "../../../node_modules/uikit/dist/css/uikit.min.css";
import "../../assets/css/style.scss";
import "../../assets/css/responsive.scss";
import "../../assets/css/flaticon.css";
import "../../assets/css/animate.min.css";

import React from "react";
import { NavLink } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import * as R from "ramda";
import Loading from "../Common/Loading";

const NavigationFive = () => {
  const { loading, error, data } = useQuery(NAVBAR);

  if (loading) return <Loading />;
  if (error) return <p>Error :(</p>;
  const { navbars } = data;

  return (
    <React.Fragment>
      <div
        id="offcanvas-flip"
        className="mobile-navbar uk-mobile-navbar"
        uk-offcanvas="flip: true; overlay: true"
      >
        <div className="uk-offcanvas-bar">
          <button
            className="uk-offcanvas-close"
            type="button"
            uk-close="true"
          ></button>

          <nav className="uk-navbar-container">
            <ul className="uk-navbar-nav">
              {navbars.map(navbar => (
                <div key={navbar.id}>
                  {navbar.type === "menu" && (
                    <li>
                      <NavLink to={navbar.url} exact>
                        {navbar.title}
                      </NavLink>

                      {!R.isEmpty(navbar.navbars) && (
                        <div uk-dropdown="true">
                          <ul className="uk-nav uk-dropdown-nav">
                            {navbar.navbars.map(subMenu => (
                              <li key={subMenu.id}>
                                {subMenu.url.trim().startsWith("http") ? (
                                  <a
                                    href={subMenu.url}
                                    key={subMenu.id}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {subMenu.title}
                                  </a>
                                ) : (
                                  <NavLink to={subMenu.url} key={subMenu.id}>
                                    {subMenu.title}
                                  </NavLink>
                                )}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </li>
                  )}
                </div>
              ))}
            </ul>
          </nav>
        </div>
      </div>
      {/* End Mobile Navbar */}

      {/* Start Navbar Area */}
      <header className="header-area">
        <div className="uk-container">
          <div className="uk-navbar">
            <div className="logo uk-navbar-left">
              <a href="/">
                <img
                  src={require("../../assets/images/llao-logo.jpg")}
                  alt="logo"
                  style={{ height: "100px" }}
                />
              </a>
            </div>

            <div
              className="uk-navbar-toggle"
              id="navbar-toggle"
              uk-toggle="target: #offcanvas-flip"
            >
              <span></span>
              <span></span>
              <span></span>
            </div>

            <div className="navbar uk-navbar-right">
              <nav className="uk-navbar-container">
                <ul className="uk-navbar-nav">
                  {navbars.map(navbar => (
                    <div key={navbar.id}>
                      {navbar.type === "menu" && (
                        <li>
                          <NavLink to={navbar.url} exact>
                            {navbar.title}
                          </NavLink>

                          {!R.isEmpty(navbar.navbars) && (
                            <div uk-dropdown="true">
                              <ul className="uk-nav uk-dropdown-nav">
                                {navbar.navbars.map(subMenu => (
                                  <li key={subMenu.id}>
                                    {subMenu.url.trim().startsWith("http") ? (
                                      <a
                                        href={subMenu.url}
                                        key={subMenu.id}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {subMenu.title}
                                      </a>
                                    ) : (
                                      <NavLink
                                        to={subMenu.url}
                                        key={subMenu.id}
                                      >
                                        {subMenu.title}
                                      </NavLink>
                                    )}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                        </li>
                      )}
                    </div>
                  ))}
                </ul>
              </nav>

              {/* <div className="lang">
                  <form>
                    <div>
                      <select>
                        <option value="eng">En</option>
                        <option value="ge">Ge</option>
                      </select>
                    </div>
                  </form>
                </div> */}
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

const NAVBAR = gql`
  {
    navbars {
      title
      url
      id
      type
      navbars {
        title
        url
        id
      }
    }
  }
`;

export default React.memo(NavigationFive);

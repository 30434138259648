import React from "react";
import { Link } from "react-router-dom";
import * as R from "ramda";
import styled from "styled-components";

const { REACT_APP_BASE_URL } = process.env;

const Image = styled.div`
  ::before {
    content: "";
    display: block;
    background-position: center center;
    background-image: ${({ src }) => `url(${src})`};
    background-size: cover;
    background-repeat: no-repeat;
    height: 0;
    padding-bottom: 100%;
  }
`;

const ServiceOneContent = ({ data }) => {
  return (
    <section id="project" className="project-area uk-project uk-section">
      <div className="uk-container">
        <div className="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
          {data.map(item => {
            return (
              <>
                {!R.isEmpty(item.interestclasses) && (
                  <div className="single-project" key={item.id}>
                    <Link to={`/school/${item.id}`} className="project-img">
                      <Image
                        src={`${REACT_APP_BASE_URL}${item.media !== null ? item.media.url : item.galleries[0].media[0].url}`}
                        alt="project"
                      />
                    </Link>

                    <div className="project-content">
                      <h3>
                        <Link to={`/school/${item.id}`}>{item.name}</Link>
                      </h3>
                      <ul>
                        <li>
                          <Link to={`/school/${item.id}`}>{item.address}</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
              </>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default ServiceOneContent;

import React from "react";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel3";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import * as R from "ramda";
import Loading from "../components/Common/Loading";
import styled from "styled-components";

const { REACT_APP_BASE_URL } = process.env;

const options = {
  loop: true,
  nav: false,
  dots: true,
  smartSpeed: 2000,
  margin: 30,
  autoplayHoverPause: true,
  autoplay: true,
  responsive: {
    0: {
      items: 1
    },
    576: {
      items: 2
    },
    786: {
      items: 2
    },
    1200: {
      items: 4
    }
  }
};

const Image = styled.div`
  ::before {
    content: "";
    display: block;
    background-position: center center;
    background-image: ${({ src }) => `url(${src})`};
    background-size: cover;
    background-repeat: no-repeat;
    height: 0;
    padding-bottom: 65%;
  }
`;

const ActivitiesHighlight = () => {
  const { loading, error, data } = useQuery(PROJECT);

  if (loading) return <Loading />;
  if (error) return <p>Error :(</p>;
  const {
    page: { galleries }
  } = data;

  return (
    <section id="project" className="project-area  uk-project uk-section">
      <div className="uk-container">
        <div className="uk-section-title section-title">
          <h2>活動花絮</h2>
          <div className="bar"></div>
        </div>
      </div>

      <OwlCarousel
        className="project-slides owl-carousel owl-theme"
        {...options}
      >
        {galleries.slice(0, 12).map(gallery => {
          const url = R.replace(
            "http://157.245.197.206",
            "",
            R.propOr("", "url", gallery)
          );
          return (
            <div className="single-project" key={gallery.id}>
              <a href={url} className="project-img">
                <Image
                  src={`${REACT_APP_BASE_URL}${gallery.media[0].url}`}
                  alt="Project"
                />
              </a>

              <div className="project-content">
                <h3>
                  <a href={url}>{gallery.title}</a>
                </h3>
              </div>
            </div>
          );
        })}
      </OwlCarousel>
    </section>
  );
};

const PROJECT = gql`
  {
    page(id: 4) {
      galleries {
        title
        description
        url
        id
        media {
          id
          url
          name
        }
      }
    }
  }
`;

export default ActivitiesHighlight;

import React from "react";
import ServiceOneContent from "../components/Projects/ProjectContent";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import Loading from "../components/Common/Loading";
import Banner from "../components/Projects/Banner";
import * as R from "ramda";

const Schools = () => {
  const {
    loading: bannerLoading,
    error: bannerError,
    data: bannerData
  } = useQuery(BANNER);
  const { loading, error, data } = useQuery(SCHOOLS);

  if (bannerLoading || loading) return <Loading />;
  if (bannerError || error) return <p>Error :(</p>;
  const { schools } = data;
  const {
    page: { imageSlider }
  } = bannerData;
  const bgImage = R.pathOr("", [0, "url"], imageSlider);

  return (
    <>
      <Banner title="興趣班" bgImage={bgImage} />
      <ServiceOneContent data={schools} />
    </>
  );
};

const SCHOOLS = gql`
  {
    schools {
      id
      name
      name_addition
      address
      galleries {
        url
        media {
          url
        }
      }
      interestclasses {
        id
      }
      media {
        id
        url
        name
      }
    }
  }
`;

const BANNER = gql`
  {
    page(id: 2) {
      imageSlider {
        url
      }
    }
  }
`;

export default Schools;

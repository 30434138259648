import Loading from "../components/Common/Loading";
import React from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";

const VideoContainer = () => {
  const { loading, error, data } = useQuery(COURSE);

  if (loading) return <Loading />;
  if (error) return <p>Error :(</p>;
  const {
    page: { blocks }
  } = data;
  const videos = blocks[1].detail.videos;

  return (
    <section id="about" className="uk-about  about-area uk-section">
      <div className="uk-container">
        <div className="uk-section-title section-title">
          <h2>影片</h2>
          <div className="bar"></div>
        </div>

        <div className="uk-grid">
          {videos.map((video, i) => (
            <div
              className="uk-width-1-2 uk-margin-medium-bottom"
              key={`${video.url}-${i}`}
            >
              <iframe
                data-uk-video
                data-uk-responsive
                src={video.url}
                frameBorder="0"
                width="1920"
                height="1080"
                key={video.title}
                title={video.title}
              ></iframe>
              <h3>{video.title}</h3>
              <p>{video.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

const COURSE = gql`
  {
    page(id: 4) {
      name
      blocks {
        title
        description
        id
        detail
      }
    }
  }
`;

export default VideoContainer;

import React from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import Loading from "../components/Common/Loading";
import Course from "../components/Common/Course";
import { useParams } from "react-router-dom";

const CourseContainer = () => {
  const { courseId } = useParams();
  const { loading, error, data } = useQuery(COURSE, {
    variables: { id: courseId }
  });

  if (loading) return <Loading />;
  if (error) return <p>Error :(</p>;
  const {
    school: { id, name, name_addition, address, courses, galleries }
  } = data;

  return (
    <Course
      id={id}
      name={name + (name_addition? ` ${name_addition}`: "")}
      address={address}
      courses={courses}
      galleries={galleries}
    />
  );
};

const COURSE = gql`
  query School($id: ID!) {
    school(id: $id) {
      id
      name
      name_addition
      address
      courses {
        id
        title
        school_course_info
        register_information
        register_fee
        registerinfos {
          id
          schools {
            id
          }
          register_information
          register_fee
        }
      }
      galleries {
        media {
          url
        }
      }
    }
  }
`;

export default CourseContainer;

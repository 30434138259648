import React from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import Intro from "../components/HomeFive/Intro";
import Loading from "../components/Common/Loading";

const About = () => {
  const { loading, error, data } = useQuery(ABOUT_US);

  if (loading) return <Loading />;
  if (error) return <p>Error :(</p>;
  const {
    block: { title, description, media }
  } = data;

  return (
    <Intro title={title} data={data} description={description} media={media} />
  );
};

const ABOUT_US = gql`
  {
    block(id: 1) {
      id
      title
      description
      subTitle
      media {
        id
        url
        name
      }
    }
  }
`;

export default About;

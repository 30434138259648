import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from "./components/Pages/Home";
import Schools from "./components/Pages/Schools";
import School from "./components/Pages/School";
import Class from "./components/Pages/Class";
import Contact from "./components/Pages/Contact";
import { ApolloProvider } from "@apollo/react-hooks";
import ApolloClient from "apollo-boost";
import Courses from "./components/Pages/Courses";
import Course from "./components/Pages/Course";
import Training from "./components/Pages/Training";
import MusicCourse from "./components/Pages/MusicCourse";
import Orchestra from "./components/Pages/Orchestra";
import TrainingCourse from "./components/Pages/TrainingCourse";

const client = new ApolloClient({
  uri: process.env.REACT_APP_GQL_BASE_URL
});

const App = () => {
  return (
    <ApolloProvider client={client}>
      <Router basename={process.env.BASE_PATH}>
        <React.Fragment>
          <Route path="/" exact component={Home} />
          <Route path="/schools" exact component={Schools} />
          <Route path="/school/:schoolId" exact component={School} />
          <Route
            path="/school/:schoolId/class/:classId"
            exact
            component={Class}
          />
          <Route path="/courses" exact component={Courses} />
          <Route path="/training" exact component={Training} />
          <Route path="/training/:courseId" exact component={MusicCourse} />
          <Route
            path="/training-course/:courseId"
            exact
            component={TrainingCourse}
          />
          <Route path="/course/:courseId" exact component={Course} />
          <Route path="/orchestra" exact component={Orchestra} />
          <Route path="/contact" exact component={Contact} />
        </React.Fragment>
      </Router>
    </ApolloProvider>
  );
};

export default App;

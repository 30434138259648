import React from "react";
import { Link } from "react-router-dom";
import * as R from "ramda";

const ServiceOneContent = ({ data, schoolId }) => {
  const sortByCourseCode = R.sortBy(R.prop("course_code"));
  return (
    <section className="services-area uk-services uk-section">
      <div className="uk-container">
        <div className="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
          {sortByCourseCode(data).map(item => {
            return (
              <div className="item" key={item.id}>
                <div className="single-services">
                  <div className="icon">{item.course_code}</div>
                  <h3>{item.title}</h3>

                  <i className="flaticon-right link-btn"></i>

                  <Link
                    to={`/school/${schoolId}/class/${item.id}`}
                    className="link uk-position-cover"
                  ></Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default React.memo(ServiceOneContent);

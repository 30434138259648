import * as R from "ramda";

import Layout from "../../Layout";
import { Link } from "react-router-dom";
import Loading from "../Common/Loading";
import React from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";

const Courses = () => {
  const { loading, error, data } = useQuery(COURSES);
  const {
    loading: descriptionLoading,
    error: descriptionError,
    data: descriptionData
  } = useQuery(OVERALL_DESCRIPTION);
  const {
    loading: badWeatherArrangementLoading,
    error: badWeatherArrangementError,
    data: badWeatherArrangementData
  } = useQuery(BAD_WEATHER_ARRANGEMENT)

  const {
    loading: admissionFlowLoading,
    error: admissionFlowError,
    data: admissionFlowData
  } = useQuery(ADMISSION_FLOW)

  if (loading || descriptionLoading || badWeatherArrangementLoading || admissionFlowLoading) return <Loading />;
  if (error || descriptionError || badWeatherArrangementError || admissionFlowError) return <p>Error :(</p>;
  const { courses } = data;
  const { block: overallDescription } = descriptionData;
  const { block: { title: badWeatherTitle, media: badWeatherMedia } } = badWeatherArrangementData;
  const { block: { title: admissionFlowTitle, media: admissionFlowMedia } } = admissionFlowData;

  let coursesInfo = [];

  return (
    <Layout>
      <section className="services-details-area uk-services-details uk-section">
        <div className="uk-container">
          <h2>靈糧教室</h2>

          <article className="uk-article services-details">
            <div className="uk-grid uk-flex">
              <div className="inner uk-width-expand">
                <div
                  className="services-details-desc"
                  style={{
                    whiteSpace: "pre-line",
                    textJustify: "inter-character",
                    textAlign: "justify"
                  }}
                >
                  {/* <h3>{overallDescription.title}</h3> */}
                  <p>{overallDescription.description}</p>
                </div>
              </div>

              <div className="uk-sidebar uk-width-1-5 uk-first-column">
                {overallDescription.media.map(image => (
                  <img
                    src={`${process.env.REACT_APP_BASE_URL}${image.url}`}
                    alt=""
                  />
                ))}
              </div>
            </div>
          </article>

          {courses.map((course, index) => {
            coursesInfo.push(course.school_course_info);
            const schoolCourses = R.pipe(
              R.groupBy(R.prop("id")),
              R.map(R.pluck("name")),
              R.map(R.flatten),
              R.map(R.uniq),
              R.toPairs,
              R.map(R.zipObj(["id", "name"]))
            )(R.flatten(coursesInfo));

            return (
              <article className="uk-article services-details">
                <div className="uk-grid uk-flex">
                  <div className="inner uk-width-expand">
                    <div
                      className="services-details-desc"
                      style={{
                        whiteSpace: "pre-line",
                        textJustify: "inter-character",
                        textAlign: "justify"
                      }}
                    >
                      <h3>{course.title}</h3>

                      <h3>課程簡介</h3>
                      <p>{course.description}</p>

                      <h3>課程內容</h3>
                      <p>{course.content}</p>
                    </div>
                  </div>

                  <div className="uk-sidebar uk-width-1-5 uk-first-column">
                    {course.thumbnail.map(image => (
                      <img
                        src={`${process.env.REACT_APP_BASE_URL}${image.url}`}
                        alt=""
                      />
                    ))}
                  </div>
                </div>

                {index === courses.length - 1 && (
                  <div className="uk-grid">
                    {schoolCourses.map(info => (
                      <Link
                        to={`/course/${info.id}`}
                        className="uk-width-1-4@m uk-width-1-2@s"
                      >
                        <div className="uk-card uk-card-default uk-card-body uk-card-hover">
                          <span
                            className="uk-card-title"
                            style={{ fontWeight: "bold" }}
                          >
                            {info.name[0]}
                          </span>
                        </div>
                      </Link>
                    ))}
                  </div>
                )}
              </article>
            );
          })}

          <br />
          <div className="services-details-desc" style={{ fontSize: "21px" }}>
            <h3>{admissionFlowTitle}</h3>
            <img
              src={`${process.env.REACT_APP_BASE_URL}${admissionFlowMedia[0].url}`}
              alt=""
            />

            <h3>{badWeatherTitle}</h3>
            <img
              src={`${process.env.REACT_APP_BASE_URL}${badWeatherMedia[0].url}`}
              alt=""
            />
            {/* <p>
              各位家長請留意電台或電視報導有關颱風或暴雨警告時所採取之特別措施,若遇有熱帶氣旋及
              持續大雨或雷雨警告。本教室所採取的一般應變措施如下:
            </p> */}
            {/* <table className="uk-table uk-table-striped uk-text-center">
              <tbody>
                <tr>
                  <td colSpan="3" className="uk-text-large">
                    <strong>熱帶氣旋及暴雨警告</strong>
                  </td>
                </tr>
                <tr>
                  <td className="uk-width-1-2" colSpan="2">
                    <strong>天氣狀況</strong>
                  </td>
                  <td className="uk-width-1-2">
                    <strong>應變措施</strong>
                  </td>
                </tr>
                <tr>
                  <td className="uk-width-1-2" colSpan="2">
                    懸掛1號熱帶氣旋警告信號或黃色暴雨警告
                  </td>
                  <td className="uk-width-1-2">照常上課</td>
                </tr>
                <tr>
                  <td className="uk-width-1-2" colSpan="2">
                    懸掛3號/8號熱帶氣旋警告信號 或 紅色/黑色暴雨警告
                  </td>
                  <td className="uk-width-1-2">停課並不獲補課及退款安排</td>
                </tr>
                <tr>
                  <td rowSpan="3" className="uk-width-1-6 uk-table-middle">
                    <strong>復課安排</strong>
                  </td>
                  <td>上午7:30或前除下警告信號</td>
                  <td>所有課堂照常</td>
                </tr>
                <tr>
                  <td>上午9:30或前除下警告信號</td>
                  <td>11:00或以後的課堂照常</td>
                </tr>
                <tr>
                  <td>中午12:00或前除下警告信號 </td>
                  <td>13:30或以後的課堂照常</td>
                </tr>
              </tbody>
            </table> */}
          </div>
        </div>
      </section>
    </Layout>
  );
};

const COURSES = gql`
  {
    courses {
      title
      id
      content
      description
      register_information
      register_fee
      school_course_info
      thumbnail {
        id
        name
        url
      }
      schools {
        name
      }
    }
  }
`;

const OVERALL_DESCRIPTION = gql`
  {
    block(id: 9) {
      id
      title
      description
      subTitle
      media {
        id
        url
        name
      }
    }
  }
`;

const BAD_WEATHER_ARRANGEMENT = gql`
{
  block(id: 2) {
    id
    title
    media {
      id
      url
      name
    }
  }
}
`;

const ADMISSION_FLOW = gql`
{
  block(id: 11) {
    id
    title
    media {
      id
      url
      name
    }
  }
}
`;


export default Courses;

import React from "react";
import NavigationFive from "./components/Navigation/NavigationFive";
import Footer from "./components/Common/Footer";

const Layout = ({ children }) => {
  return (
    <React.Fragment>
      <NavigationFive />
      {children}
      <Footer />
    </React.Fragment>
  );
};

export default Layout;

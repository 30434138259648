import React from "react";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel3";
import * as R from "ramda";

const { REACT_APP_BASE_URL } = process.env;

const options = {
  items: 1,
  loop: true,
  nav: false,
  dots: true,
  smartSpeed: 2000,
  margin: 30,
  autoplayHoverPause: true,
  autoplay: true
};

const DetailsContent = ({ data, sidebar, schoolId }) => {
  return (
    <section className="services-details-area uk-services-details uk-section">
      <div className="uk-container">
        <article className="uk-article services-details">
          <div className="uk-grid uk-flex">
            <div className="inner uk-width-expand">
              <div className="services-details-desc">
                <h3>{data.title}</h3>
                <h4>課程編號：{data.course_code}</h4>

                {data.tutor_intro && (
                  <blockquote className="blockquote">
                    {data.tutor_intro}
                  </blockquote>
                )}

                <p>{data.course_detail}</p>
                {data.register_form_url && (
                  <p>
                    <a
                      href={data.register_form_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      報名表
                    </a>
                  </p>
                )}

                <OwlCarousel
                  className="services-image-slides owl-carousel owl-theme"
                  {...options}
                >
                  {!R.isNil(data.media) &&
                    R.flatten(data.media).map(item => {
                      return (
                        <div className="item" key={item.url}>
                          <img
                            src={`${REACT_APP_BASE_URL}${item.url}`}
                            alt="img"
                          />
                        </div>
                      );
                    })}
                </OwlCarousel>
              </div>
            </div>

            <div className="uk-sidebar uk-width-1-5 uk-flex-first@l uk-first-column">
              <div className="widget service_list">
                <ul>
                  {sidebar &&
                    sidebar.map(course => {
                      return (
                        <li key={course.id}>
                          <Link to={`/school/${schoolId}/class/${course.id}`}>
                            {course.title} <i className="flaticon-right"></i>
                          </Link>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>
        </article>
      </div>
    </section>
  );
};

export default React.memo(DetailsContent);

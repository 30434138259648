import React from "react";
import styled from "styled-components";

const { REACT_APP_BASE_URL } = process.env;

const PageTitleArea = styled.section`
  &::before {
    background-image: url(${REACT_APP_BASE_URL}${({ bgImage }) => bgImage});
  }
`;

const Banner = ({ title, bgImage }) => {
  return (
    <PageTitleArea className="page-title-area uk-page-title" bgImage={bgImage}>
      <div className="uk-container">
        <h1 style={{ textShadow: "2px 2px 2px #333" }}>{title}</h1>
      </div>
    </PageTitleArea>
  );
};

export default React.memo(Banner);

import React from "react";
import ServiceOneContent from "../components/Services/ServiceOneContent";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { useParams } from "react-router-dom";
import Banner from "../components/Projects/Banner";
import Loading from "../components/Common/Loading";

const School = () => {
  const { schoolId } = useParams();
  const { loading, error, data } = useQuery(SCHOOL, {
    variables: { id: schoolId }
  });

  if (loading) return <Loading />;
  if (error) return <p>Error :(</p>;
  const {
    school: { interestclasses, name }
  } = data;

  return (
    <>
      <Banner title={name} />
      <ServiceOneContent data={interestclasses} schoolId={schoolId} />
    </>
  );
};

const SCHOOL = gql`
  query School($id: ID!) {
    school(id: $id) {
      id
      name
      interestclasses {
        id
        title
        course_code
      }
    }
  }
`;

export default School;

import React from "react";
import Footer from "../Common/Footer";
import Schools from "../../containers/Schools";
import NavigationFive from "../Navigation/NavigationFive";

class Projects extends React.Component {
  render() {
    return (
      <React.Fragment>
        <NavigationFive />
        <Schools />
        <Footer />
      </React.Fragment>
    );
  }
}

export default Projects;

import React from "react";
import Layout from "../../Layout";
import { useQuery } from "@apollo/react-hooks";
import { useParams } from "react-router-dom";
import * as R from "ramda";
import OwlCarousel from "react-owl-carousel3";
import gql from "graphql-tag";
import Loading from "../Common/Loading";

const { REACT_APP_BASE_URL } = process.env;

const options = {
  items: 1,
  loop: true,
  nav: false,
  dots: true,
  smartSpeed: 2000,
  margin: 30,
  autoplayHoverPause: true,
  autoplay: true
};

const MusicCourse = () => {
  const { courseId } = useParams();
  const { loading, error, data } = useQuery(COURSE, {
    variables: { id: courseId }
  });

  if (loading) return <Loading />;
  if (error) return <p>Error :(</p>;
  const { musiccourse } = data;

  return (
    <Layout>
      <section className="services-details-area uk-services-details uk-section">
        <div className="uk-container">
          <h2>{musiccourse.title}</h2>
          <article className="uk-article services-details" key={musiccourse.id}>
            <div className="uk-grid uk-flex">
              <div className="inner uk-width-expand">
                <div className="services-details-desc">
                  <p>{musiccourse.description}</p>

                  <p>{musiccourse.content}</p>

                  <p>{musiccourse.tutor_intro}</p>

                  <h4>學費</h4>
                  <p>{musiccourse.register_fee}</p>
                  <br />
                </div>
              </div>

              <div className="uk-sidebar uk-width-1-5 uk-first-column">
                <OwlCarousel
                  className="services-image-slides owl-carousel owl-theme"
                  {...options}
                >
                  {!R.isNil(musiccourse.media) &&
                    R.flatten(musiccourse.media).map(item => {
                      return (
                        <div className="item" key={item.url}>
                          <img
                            src={`${REACT_APP_BASE_URL}${item.url}`}
                            alt="img"
                          />
                        </div>
                      );
                    })}
                </OwlCarousel>
              </div>
            </div>
          </article>
        </div>
      </section>
    </Layout>
  );
};

const COURSE = gql`
  query MusicCourse($id: ID!) {
    musiccourse(id: $id) {
      title
      id
      description
      content
      tutor_intro
      register_fee
      media {
        url
      }
    }
  }
`;

export default React.memo(MusicCourse);

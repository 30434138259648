import * as R from "ramda";

import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import Loading from "../Common/Loading";
import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/react-hooks";

const startYear = 2008;
const currentYear = new Date().getFullYear();
const throughNBySix = R.curry((limit, n) => (n > limit ? false : [n, n + 5]));
const yearsArray = R.unfold(throughNBySix(currentYear), startYear);

const largerThanCurrentYear = n =>
  n + 4 > currentYear ? currentYear : `${n + 4}`;

const transformedYearsObj = R.map(
  R.applySpec({
    year: n =>
      n < currentYear ? `${n} - ${largerThanCurrentYear(n)}` : `${n}`,
    from: n => n,
    to: R.add(4)
  }),
  yearsArray
);

const reverseTransformedYearsObj = R.reverse(transformedYearsObj);
const MusicCourseParticipants = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [getActivities, { loading, data }] = useLazyQuery(
    MUSICCOURSEPARTICIPANTS
  );

  useEffect(() => {
    getActivities({
      variables: {
        where: {
          from_gte: new Date(
            String(reverseTransformedYearsObj[0].from)
          ).toISOString(),
          to_lt: new Date(
            String(reverseTransformedYearsObj[0].to)
          ).toISOString()
        }
      }
    });
  }, []);

  if (loading) return <Loading />;

  return (
    <section className="services-details-area uk-services-details uk-section-small">
      <div className="uk-container">
        <article className="uk-article services-details">
          <div className="uk-grid uk-flex">
            <div className="inner uk-width-expand">
              <div className="services-details-desc">
                <table className="uk-table uk-table-striped">
                  <thead>
                    <tr>
                      <th style={{ fontSize: "20px" }}>
                        <strong>日期</strong>
                      </th>
                      <th style={{ fontSize: "20px" }}>
                        <strong>培訓內容</strong>
                      </th>
                      {/* <th style={{ fontSize: "20px" }}>
                        <strong>培訓內容</strong>
                      </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {data &&
                      data.musiccourseparticipants
                        .sort((a, b) => {
                          return (
                            new Date(String(b.from)).getTime() -
                            new Date(String(a.from)).getTime()
                          );
                        })
                        .map(item => {
                          const fromDate = item.from
                            .replace(/-/g, "/")
                            .substring(0, 10);
                          const toDate = item.to
                            .replace(/-/g, "/")
                            .substring(0, 10);
                          const dateStr =
                            fromDate === toDate
                              ? fromDate
                              : `${fromDate} - ${toDate}`;
                          return (
                            <tr key={item.id}>
                              <td>{dateStr}</td>
                              <td>{item.participant}</td>
                              {/* <td>{item.content}</td> */}
                            </tr>
                          );
                        })}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="uk-sidebar uk-width-1-4@m uk-flex-first@m uk-first-column">
              <div className="widget service_list">
                <ul>
                  {reverseTransformedYearsObj.map((item, i) => {
                    return (
                      <li key={item.year}>
                        <Link
                          to="#"
                          className={i === activeIndex ? "active" : ""}
                          onClick={() => {
                            getActivities({
                              variables: {
                                where: {
                                  from_gte: new Date(
                                    String(item.from)
                                  ).toISOString(),
                                  to_lt: new Date(
                                    String(item.to + 1)
                                  ).toISOString()
                                }
                              }
                            });
                            setActiveIndex(i);
                          }}
                        >
                          {item.year} <i className="flaticon-right"></i>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </article>
      </div>
    </section>
  );
};

const MUSICCOURSEPARTICIPANTS = gql`
  query MusicCourseParticipants($where: JSON) {
    musiccourseparticipants(where: $where) {
      participant
      from
      to
      id
    }
  }
`;

export default React.memo(MusicCourseParticipants);

import * as R from "ramda";

import Layout from "../../Layout";
import { Link } from "react-router-dom";
import Loading from "../Common/Loading";
import MusicCourseParticipants from "../Services/MusicCourseParticipants";
import React from "react";
import TrainingCourseContents from "../Services/TrainingCourseContents";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";

const Training = () => {
  const { loading, error, data } = useQuery(COURSES);

  if (loading) return <Loading />;
  if (error) return <p>Error :(</p>;
  const {
    page: { blocks, musiccourses, trainingcourses }
  } = data;

  const alignStyle = {
    whiteSpace: "pre-line",
    textJustify: "inter-character",
    textAlign: "justify"
  };

  return (
    <Layout>
      <section className="services-details-area uk-services-details uk-section">
        <div className="uk-container">
          {blocks.map(course => {
            return (
              <article className="uk-article services-details">
                <div className="uk-grid uk-flex">
                  <div className="inner uk-width-expand">
                    <div
                      className="services-details-desc"
                      style={{ minHeight: "420px" }}
                    >
                      <h3>{course.title}</h3>

                      {course.description && (
                        <>
                          <h3>課程簡介</h3>
                          <p style={alignStyle}>{course.description}</p>
                        </>
                      )}
                      {course.type === 'participated_organization' && <MusicCourseParticipants />}
                      {course.type === 'training_course_content' && <TrainingCourseContents />}
                    </div>
                  </div>

                  {!R.isEmpty(course.media) && (
                    <div
                      className="uk-sidebar uk-width-1-5 uk-first-column"
                      style={{ marginTop: "6em" }}
                    >
                      {course.media.map(image => (
                        <img
                          src={`${process.env.REACT_APP_BASE_URL}${image.url}`}
                          alt=""
                          className="uk-margin-medium-bottom"
                        />
                      ))}
                    </div>
                  )}
                </div>
              </article>
            );
          })}

          <div className="uk-grid uk-grid-match uk-child-width-expand@s mt-2">
            {!R.isEmpty(musiccourses) &&
              musiccourses.map(info => (
                <Link
                  to={
                    info.interest_class_url
                      ? info.interest_class_url
                      : `/training/${info.id}`
                  }
                  className="uk-width-1-4@m uk-width-1-2@s uk-margin-medium-bottom"
                >
                  <div className="uk-card uk-card-default uk-card-body uk-card-hover">
                    <span className="uk-card-title">{info.title}</span>
                  </div>
                </Link>
              ))}
            {!R.isEmpty(trainingcourses) &&
              trainingcourses.map(info => (
                <Link
                  to={`/training-course/${info.id}`}
                  className="uk-width-1-4@m uk-width-1-2@s uk-margin-medium-bottom"
                >
                  <div className="uk-card uk-card-default uk-card-body uk-card-hover">
                    <span className="uk-card-title">{info.title}</span>
                  </div>
                </Link>
              ))}
          </div>
        </div>
      </section>
    </Layout>
  );
};

const COURSES = gql`
  {
        page(id: 5) {
        name
      blocks {
        title
        description
      detail
      type
      id
        media {
        name
          url
    }
  }
      musiccourses {
        id
        title
      description
      content
      tutor_intro
      register_fee
        media {
        url
      }
      interest_class_url
    }
      trainingcourses {
        title
        description
      content
      register_fee
      school_course_info
      id
    }
  }
}
`;

export default Training;

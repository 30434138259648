import React from "react";
import SchoolContainer from "../../containers/School";
import Layout from "../../Layout";

const School = () => {
  return (
    <Layout>
      <SchoolContainer />
    </Layout>
  );
};

export default School;

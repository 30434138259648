import React from "react";

const Loading = () => {
  return (
    <div className="uk-grid uk-flex-center">
      <div data-uk-spinner="" />
    </div>
  );
};

export default Loading;

import * as R from "ramda";

import Layout from "../../Layout";
import OwlCarousel from "react-owl-carousel3";
import React from "react";
import { useRouteMatch } from "react-router-dom";

const { REACT_APP_BASE_URL } = process.env;

const options = {
  items: 1,
  loop: true,
  nav: false,
  dots: true,
  smartSpeed: 2000,
  margin: 30,
  autoplayHoverPause: true,
  autoplay: true
};

const alignStyle = {
  whiteSpace: "pre-line",
  textJustify: "inter-character",
  textAlign: "justify"
};

const Course = ({ id, name, courses, galleries, registerForm }) => {
  let registerInformation, registerFee;
  const isTrainingCourse = useRouteMatch("/training-course");

  return (
    <Layout>
      <section className="services-details-area uk-services-details uk-section">
        <div className="uk-container">
          <h2>{name}</h2>

          {!R.isEmpty(courses) &&
            courses.map((course, index) => {
              const courseDetails = R.find(
                R.propEq("id", +id),
                course.school_course_info
              );
              registerInformation = R.path(["register_information"], course);
              registerFee = R.path(["register_fee"], course);
              let registerInfos = R.path(["registerinfos"], course)
              if (!R.isEmpty(registerInfos) && registerInfos) {
                registerInfos.forEach(registerInfoAlt => {
                  let registerSchools = R.path(["schools"], registerInfoAlt)
                  registerSchools.forEach(school => {
                    if (school.id === id){
                      registerInformation = R.path(["register_information"], registerInfoAlt)
                      registerFee = R.path(["register_fee"], registerInfoAlt)
                    }
                  })
                })
                
              }

              return (
                <article
                  className="uk-article services-details"
                  key={course.id}
                >
                  <div className="uk-grid uk-flex">
                    <div className="inner uk-width-expand">
                      <div className="services-details-desc">
                        <h3>{course.title}</h3>

                        {course.description && (
                          <p style={alignStyle}>{course.description}</p>
                        )}

                        {course.content && (
                          <p style={alignStyle}>{course.content}</p>
                        )}

                        {courseDetails.target && (
                          <>
                            <h3>招收對象</h3>
                            <p>{courseDetails.target}</p>
                          </>
                        )}

                        <h3>上課日期</h3>
                        <p style={alignStyle}>{courseDetails.day}</p>

                        {!R.isEmpty(courseDetails.time) && (
                          <>
                            <h3>上課時間</h3>
                            <ul className="uk-list" >
                              {courseDetails.time.map(time => (
                                <li>{time}</li>
                              ))}
                            </ul>
                          </>
                        )}

                        <h3>上課地點</h3>
                        <p>{courseDetails.address}</p>

                        {courseDetails.fee && (
                          <>
                            <h3>學費</h3>
                            <p>{courseDetails.fee}</p>
                          </>
                        )}
                        <br />
                        {courseDetails.formUrl && (
                          <p>
                            <a
                              href={courseDetails.formUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              網上報名表
                            </a>
                          </p>
                        )}

                        {registerInformation && (
                          <>
                            <h3>報名辦法</h3>
                            {/* <p>{registerInformation}</p> */}
                            <ul className="uk-list uk-list-bullet">
                              {registerInformation.split("\n").slice().map(item => (
                                <li>{item}</li>
                              ))}
                            </ul>
                          </>
                        )}
                        {registerFee && (
                          <>
                            <h3>{isTrainingCourse ? "學費" : "報名費用"}</h3>
                            <p>{registerFee}</p>
                          </>
                        )}
                        {registerForm &&
                          registerForm.map(item => (
                            <p>
                              <a
                                href={item.url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {item.title}
                              </a>
                            </p>
                          ))}
                      </div>
                    </div>

                    <div className="uk-sidebar uk-width-1-5 uk-first-column">
                      {!R.isNil(galleries[index]) && (
                        <OwlCarousel
                          className="services-image-slides owl-carousel owl-theme"
                          {...options}
                        >
                          {R.flatten(galleries[index].media).map(item => {
                            return (
                              <div className="item" key={item.url}>
                                <img
                                  src={`${REACT_APP_BASE_URL}${item.url}`}
                                  alt="img"
                                />
                              </div>
                            );
                          })}
                        </OwlCarousel>
                      )}
                    </div>
                  </div>
                </article>
              );
            })}
        </div>
      </section>
    </Layout>
  );
};

export default React.memo(Course);

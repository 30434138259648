import React from "react";
import * as R from 'ramda'
import Banner from "../../components/Projects/Banner";
import Layout from "../../Layout";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import Loading from "../Common/Loading";

const ContactUs = () => {
  const { loading, error, data } = useQuery(SCHOOLS);

  if (loading) return <Loading />;
  if (error) return <p>Error :(</p>;
  const { schools } = data;

  return (
    <Layout>
      <Banner title="聯絡我們" />

      <section id="contact" className="contact-area uk-contact uk-section">
        <div className="uk-container">
          {schools
            .filter((item) => !R.isNil(item.phone) && !R.isEmpty(item.phone.replace(/ /g,'')))
            .map((item, i) => {
            return (
              <div
                className="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s"
                key={item.name}
              >
                <div className="item">
                  <h3>
                    <strong>
                      {item.name}
                      <br />
                      {item.eng_name}
                    </strong>
                  </h3>
                  <p>
                    地址: {item.address}
                    <br />
                    電話查詢: {item.phone}
                    <br />
                    傳真: {item.fax}
                    <br />
                    電郵: {item.email}
                    <br />
                    {item.fb_url && (
                      <p>
                        <a
                          href={item.fb_url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Facebook
                        </a>
                      </p>
                    )}
                    <br />
                    辦公時間:
                    {Object.values(item.office_hour).map(time => (
                      <span key={time.title} style={{ display: "block" }}>
                        {time.open}
                        <br />
                        {time.close}
                      </span>
                    ))}
                  </p>
                </div>
                <div className="item">
                  <iframe
                    title={item.title}
                    src={item.google_map_url}
                    width="600"
                    height="450"
                    frameBorder="0"
                    style={{ border: 0 }}
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </Layout>
  );
};

const SCHOOLS = gql`
  {
    schools {
      id
      name
      eng_name
      address
      email
      phone
      fax
      office_hour
      fb_url
      google_map_url
    }
  }
`;

export default ContactUs;

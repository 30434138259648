import React from "react";
import footerShapeOne from "../../assets/images/footer-shape1.png";
import footerShapeTwo from "../../assets/images/footer-shape2.png";
import GoTop from "./GoTop";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import Loading from "./Loading";

const Footer = () => {
  const { loading, error, data } = useQuery(FOOTER);

  if (loading) return <Loading />;
  if (error) return <p>Error :(</p>;
  const {
    footer: { copyright, schools, social_media }
  } = data;

  return (
    <footer className="footer-area uk-dark uk-footer">
      <div className="uk-container">
        <div className="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-4@m uk-child-width-1-2@s">
          {/* <div className="item">
            <div className="single-footer-widget">
              <div className="logo">
                <Link to="/">
                  <img src={logo} alt="logo" />
                </Link>
              </div>

              <p>{description}</p>
            </div>
          </div> */}

          {schools.map(school => {
            return (
              <div className="item" key={school.name}>
                <div className="single-footer-widget">
                  <h3>{school.name}</h3>
                  <div className="bar"></div>

                  <div className="location">
                    <ul className="contact-info">
                      <li>{school.address}</li>
                      <li>Tel: {school.phone}</li>
                      <li>Fax: {school.fax}</li>
                      <li>
                        <a href={`mailto:${school.email}`}>{school.email}</a>
                      </li>
                    </ul>
                    <br />
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="copyright-area">
          <div className="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-2@s">
            <div className="item">
              <p className="uk-text-small">{copyright}</p>
            </div>
            <div className="item">
              <div className="single-footer-widget">
                <ul
                  className="social"
                  style={{ display: "flex", margin: "0 0 1em" }}
                >
                  {social_media.facebook.map(item => {
                    return (
                      <li
                        key={item.title}
                        style={{
                          display: "flex",
                          alignItems: "center"
                        }}
                      >
                        <a
                          href={item.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="flaticon-logo"></i>
                        </a>
                        <div style={{ marginLeft: "0.5em" }}>{item.title}</div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>

            {/* <div className="item">
              <ul>
                <li>
                  <Link to="#">Terms & Conditions</Link>
                </li>
                <li>
                  <Link to="#">Privacy Policy</Link>
                </li>
              </ul>
            </div> */}
          </div>
          <GoTop scrollStepInPx="50" delayInMs="16.66" />
        </div>
      </div>

      <div className="br-line"></div>
      <div className="footer-shape1">
        <img src={footerShapeOne} alt="shape" />
      </div>
      <div className="footer-shape2">
        <img src={footerShapeTwo} alt="shape" />
      </div>
    </footer>
  );
};

const FOOTER = gql`
  {
    footer(id: 1) {
      title
      description
      copyright
      id
      social_media
      schools {
        name
        address
        phone
        fax
        email
        id
      }
    }
  }
`;

export default Footer;

import 'react-image-lightbox/style.css';

import React, { useState } from "react";

import Lightbox from 'react-image-lightbox';
import { Link } from "react-router-dom";
import Loading from "../Common/Loading";
import OwlCarousel from "react-owl-carousel3";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";

const { REACT_APP_BASE_URL } = process.env;

const options = {
  nav: false,
  dots: true,
  smartSpeed: 2000,
  margin: 30,
  autoplayHoverPause: true,
  autoplay: true,
  responsive: {
    0: {
      items: 1
    },
    576: {
      items: 2
    },
    786: {
      items: 2
    },
    1200: {
      items: 3
    }
  }
};

const LatestNews = () => {
  const { loading, error, data } = useQuery(LATESTNEWS);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setModelOpen] = useState(false);

  if (loading) return <Loading />;
  if (error) return <p>Error :(</p>;
  const {
    page: { latestnews }
  } = data;

  const images = [];

  return (
    <section id="blog" className="blog-area  uk-blog uk-section">
      <div className="uk-container">
        <div className="uk-section-title section-title">
          {/* <span>Our Company Blog</span> */}
          <h2>最新消息</h2>
          <div className="bar"></div>

          {/* <Link to="#" className="uk-button uk-button-default">
            View All
          </Link> */}
        </div>

        <OwlCarousel
          className="blog-slides owl-carousel owl-theme"
          {...options}
        >
          {latestnews.slice(0, 8).map((news, index) => {
            const date = new Date(news.date);
            images.push(`${REACT_APP_BASE_URL}${news.thumbnail.url}`)
            return (
              <div className="single-blog-post" key={news.id}>
                <div className="blog-post-image">
                  {/* <a href={news.url}> */}
                  <img
                    src={`${REACT_APP_BASE_URL}${news.thumbnail.url}`}
                    alt="news"
                    onClick={() => { setPhotoIndex(index); setModelOpen(!isOpen) }}
                  />
                  {/* </a> */}
                </div>

                <div className="blog-post-content">
                  {/* <span className="date">{`${date.getMonth() +
                    1}月${date.getDate()}日`}</span> */}
                  <h3 onClick={() => { setPhotoIndex(index); setModelOpen(!isOpen) }}>
                    {/* <Link to="/blog-details">{news.title}</Link> */}
                    {news.title}
                  </h3>
                  {/* <Link to="#" className="read-more">
                    閱讀更多
                  </Link> */}
                </div>
              </div>
            );
          })}
        </OwlCarousel>

        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            // nextSrc={images[(photoIndex + 1) % images.length]}
            // prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => setModelOpen(!isOpen)}
          // onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
          // onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
          // enableZoom={false}
          />
        )}


      </div>
    </section>
  );
};

const LATESTNEWS = gql`
  {
    page(id: 1) {
      latestnews {
        title
        id
        url
        date
        thumbnail {
          id
          url
          name
        }
      }
    }
  }
`;

export default LatestNews;

import React from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import Loading from "../components/Common/Loading";
import Course from "../components/Common/Course";
import { useParams } from "react-router-dom";

const TrainingCourseContainer = () => {
  const { courseId } = useParams();
  const { loading, error, data } = useQuery(TRAINING_COURSE, {
    variables: { id: courseId }
  });

  if (loading) return <Loading />;
  if (error) return <p>Error :(</p>;
  const {
    trainingcourse: {
      id,
      title,
      school_course_info,
      register_fee,
      register_form,
      description,
      content,
      gallery
    }
  } = data;
  const courses = [
    {
      id,
      school_course_info,
      register_fee,
      description,
      content
    }
  ];

  return (
    <Course
      id={id}
      name={title}
      courses={courses}
      galleries={[{ media: gallery }]}
      registerForm={register_form}
    />
  );
};

const TRAINING_COURSE = gql`
  query Trainingcourse($id: ID!) {
    trainingcourse(id: $id) {
      title
      description
      content
      register_fee
      register_form
      school_course_info
      id
      gallery {
        id
        url
        name
      }
    }
  }
`;

export default TrainingCourseContainer;

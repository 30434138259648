import React from "react";
import OwlCarousel from "react-owl-carousel3";
import styled from "styled-components";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import Loading from "../Common/Loading";

const { REACT_APP_BASE_URL } = process.env;

const options = {
  loop: true,
  nav: false,
  dots: true,
  smartSpeed: 2000,
  margin: 0,
  autoplayHoverPause: true,
  autoplay: true,
  items: 1
};

const BannerItem = styled.div`
  min-height: 450px;
  position: relative;
  background: url(${({ bgImage }) => bgImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (max-width: 992px) {
    min-height: 350px;
  }
`;

const BannerContainer = styled.div`
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  z-index: 10;
  width: 100%;
  text-align: center;
`;

const Wrapper = styled.section`
  && {
    .blog-slides.owl-theme .owl-dots {
      position: absolute;
      bottom: 20px;
      width: 100%;
    }
  }
`;

const Banner = () => {
  const { loading, error, data } = useQuery(BANNER);

  if (loading) return <Loading />;
  if (error) return <p>Error :(</p>;
  const {
    page: { imageSlider }
  } = data;

  return (
    <Wrapper id="home">
      <OwlCarousel className="blog-slides owl-carousel owl-theme" {...options}>
        {imageSlider.slice(0, 4).map(item => {
          return (
            <BannerItem
              bgImage={`${REACT_APP_BASE_URL}${item.url}`}
              key={item.id}
            />
          );
        })}
      </OwlCarousel>
    </Wrapper>
  );
};

const BANNER = gql`
  {
    page(id: 1) {
      imageSlider {
        id
        url
        name
      }
    }
  }
`;

export default Banner;

import React from "react";
import OwlCarousel from "react-owl-carousel3";
import styled from "styled-components";
import { useRouteMatch } from "react-router-dom";
const { REACT_APP_BASE_URL } = process.env;

const options = {
  nav: false,
  dots: true,
  smartSpeed: 2000,
  margin: 0,
  autoplay: false,
  items: 1
};

const BannerItem = styled.div`
  min-height: 450px;
  position: relative;
  background: url(${({ bgImage }) => bgImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const Intro = ({ data, description, media, title }) => {
  const isOrchestra = useRouteMatch("/orchestra");

  return (
    <>
      {data && (
        <section id="about" className="uk-about  about-area uk-section">
          <div className="uk-container">
            <div className="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
              <div className="item">
                <div className="about-content">
                  <div className="uk-section-title section-title">
                    <h2>{title}</h2>
                    <div className="bar"></div>
                  </div>

                  <div className="about-text">
                    <div className="icon">
                      <i className="flaticon-quality"></i>
                    </div>
                    <p
                      style={{
                        whiteSpace: "pre-line",
                        textJustify: "inter-character",
                        textAlign: "justify",
                        lineHeight: "1.45"
                      }}
                    >
                      {!isOrchestra ? (
                        <>
                          <h2>{description.split("\n").slice(0, 1)}</h2>
                          {description.split("\n").splice(1)}
                        </>
                      ) : (
                        description
                      )}
                    </p>
                  </div>
                </div>
              </div>

              {media && (
                <div className="item" style={{ display: "block" }}>
                  {media.length > 2 ? (
                    <OwlCarousel
                      className="blog-slides owl-carousel owl-theme"
                      {...options}
                    >
                      {media.map(item => {
                        return (
                          <BannerItem
                            bgImage={`${REACT_APP_BASE_URL}${item.url}`}
                            key={item.id}
                          />
                        );
                      })}
                    </OwlCarousel>
                  ) : (
                    media.length !== 0 &&
                    media.map(item => (
                      <div className="about-img">
                        <img
                          src={`${REACT_APP_BASE_URL}${item.url}`}
                          className="about-img1"
                          alt="about-img"
                          key={item.url}
                        />
                      </div>
                    ))
                  )}
                </div>
              )}
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Intro;

import React from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { useParams } from "react-router-dom";
import DetailsContent from "../components/Services/DetailsContent";
import Banner from "../components/Projects/Banner";
import Loading from "../components/Common/Loading";

const Class = () => {
  const { classId, schoolId } = useParams();
  const { loading, error, data } = useQuery(CLASS, {
    variables: { id: classId }
  });
  const {
    loading: schoolLoading,
    error: schoolError,
    data: schoolData
  } = useQuery(SCHOOL, {
    variables: { schoolId }
  });

  if (loading || schoolLoading) return <Loading />;
  if (error || schoolError) {
    return <p>Error :(</p>;
  }
  const { interestclass } = data;
  const {
    school: { interestclasses, name }
  } = schoolData;

  return (
    <>
      <Banner title={name} />
      <DetailsContent
        data={interestclass}
        sidebar={interestclasses}
        schoolId={schoolId}
      />
    </>
  );
};

const CLASS = gql`
  query interestclass($id: ID!) {
    interestclass(id: $id) {
      id
      title
      course_code
      course_detail
      tutor_intro
      register_form_url
      gallery {
        url
        id
        media {
          url
        }
      }
      media {
        url
      }
    }
  }
`;

const SCHOOL = gql`
  query School($schoolId: ID!) {
    school(id: $schoolId) {
      name
      interestclasses {
        id
        title
      }
    }
  }
`;

export default Class;

import React from "react";

import Layout from "../../Layout";
import OrchestraIntro from "../../containers/OrchestraIntro";
import PassActivities from "../../components/Services/PassActivities";
import ActivitiesHighlight from "../../containers/ActivitiesHighlight";
import SeperatedBar from "../HomeFive/SeperatedBar";
import VideoContainer from "../../containers/OrchestraVideo";

class Orchestra extends React.Component {
  render() {
    return (
      <Layout>
        <OrchestraIntro />
        <SeperatedBar />
        <ActivitiesHighlight />
        <SeperatedBar />
        <VideoContainer />
        <SeperatedBar />
        <PassActivities />
      </Layout>
    );
  }
}

export default Orchestra;

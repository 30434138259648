import React from "react";
import Banner from "../HomeFive/Banner";
import SeperatedBar from "../HomeFive/SeperatedBar";
import About from "../../containers/About";
import Project from "../HomeFive/Project";
import LatestNews from "../HomeFive/LatestNews";
import Layout from "../../Layout";
import VideoContainer from "../../containers/Video";

class Home extends React.Component {
  render() {
    return (
      <Layout>
        <Banner />
        <About />
        <SeperatedBar />
        <LatestNews />
        <SeperatedBar />
        <VideoContainer />
        <SeperatedBar />
        <Project />
      </Layout>
    );
  }
}

export default Home;

import React from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import Intro from "../components/HomeFive/Intro";
import Loading from "../components/Common/Loading";

const OrchestraIntro = () => {
  const { loading, error, data } = useQuery(ABOUT_US);

  if (loading) return <Loading />;
  if (error) return <p>Error :(</p>;
  const {
    page: { blocks }
  } = data;
  const { title, description, media } = blocks[0];

  return (
    <Intro title={title} data={data} description={description} media={media} />
  );
};

const ABOUT_US = gql`
  {
    page(id: 4) {
      name
      blocks {
        title
        description
        id
        media {
          id
          url
          name
        }
      }
    }
  }
`;

export default OrchestraIntro;
